.note-entry {
    background-color: var(--card-bg, #fff); /* Light background color */
    border-radius: 10px; /* Keep rounded corners */
    padding: 10px; /* Add some internal spacing */
    margin: 5px 10px; /* Add space around the cards */
    max-width: calc(100% - 20px); /* Ensure it doesn't exceed the sidebar's width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); /* Optional shadow for depth */
  }
  
  
  .note-entry:hover {
    transform: scale(1.02); /* Slight zoom on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Enhances shadow on hover */
  }
  
  .darkMode .note-entry {
    background-color: #333; /* Darker background for dark mode */
    color: #fff; /* Text contrast in dark mode */
  }
  