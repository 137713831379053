/* temp comment */
/* Styles for the chatbot toggle button */
.chatbot-toggle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

/* Styles for the chatbot container */
.chatbot-container {
    position: fixed;
    bottom: 70px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.chatbot-container h1 {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
}

.chatbot-container textarea {
    width: 90%;
    margin: 10px auto;
}

.chatbot-container button {
    margin: 10px;
    align-self: center;
}